import React from "react";
import "./SinglePost.scss";
import TimeFormat from "../../components/TimeFormat/TimeFormat";
import { urlTitle2 } from "../../shared/utillity";
import { useIdContext } from "../../shared/IdProvider";
import { useGlobalLocation } from "../../shared/LocationContext";
import { Link } from "react-router";
import BodyText from "../../components/BodyText/BodyText";
import { Helmet } from "react-helmet-async";
import { useImageSize } from "react-image-size";

export default function SinglePost() {
  window.scrollTo(0, 0);
  const { location } = useGlobalLocation();
  const { category, post } = useIdContext();
  const [dimensions, { loading, error }] = useImageSize(`${post.pics[0]}`);
  // console.log("Dimens", dimensions);
  console.log("Single post");
  const setSource = (item) => {
    if (Array.isArray(item)) {
      return <div>{`Autor: ${item[1]}`}</div>;
    } else {
      // return `Izvor: ${item}`;
      return <div dangerouslySetInnerHTML={{ __html: "Izvor: " + item }}></div>;
    }
  };
  const setSource2 = (item) => {
    return Array.isArray(item) ? `${item[1]}` : "gardenia.rs";
  };
  const setModifiedTime = (item) => {
    if (item) {
      return <meta property="article:modified_time" content={post.modified} />;
    }
  };
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: post.title_2 || post.title,
    description: post.lead,
    datePublished: post.time2,
    // dateModified: post.modified || null,
    dateModified: post.modified || post.time2,
    author: {
      "@type": "Person",
      name: post.source[1] || "Gardenia",
    },
    publisher: {
      "@type": "Organization",
      name: "Gardenia",
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": post.testUrl,
    },
    image: {
      "@type": "ImageObject",
      url: post.pics[1],
      width: dimensions?.width,
      height: dimensions?.height,
    },
  };

  return (
    <div className={post.idNum ? post.idNum : "singlePost"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{post.title_2 || post.title}</title>
        <meta name="description" content={post.lead} />
        <link rel="canonical" href={"https://gardenia.rs" + location.pathname} />
        <link rel="manifest" href="/manifest.json" />
        {/* Opengraph - Facebook */}
        <meta name="title" content={post.title_2 || post.title} />
        <meta property="og:title" content={post.title_2 || post.title} />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="sr-RS" />
        {/* <meta property="article:publisher" content="https://www.facebook.com/gardenia.portal" /> */}
        <meta name="author" content={setSource2(post.source)} />
        <meta property="og:site_name" content="Gardenia.rs" />
        <meta property="og:image" content={post.pics[0]} />
        <meta property="og:image:secure_url" content={post.pics[0]} />
        <meta name="robots" content="max-image-preview:large" />
        {/* <meta property="og:image:width" content={`${dimensions?.width}`} /> */}
        {/* <meta property="og:image:height" content={`${dimensions?.height}`} /> */}
        <meta property="og:image:alt" content={post.title_2 || post.title} />
        {/* <meta property="og:image:type" content="image/jpeg" /> */}
        <meta property="og:url" content={"https://gardenia.rs" + location.pathname} />
        <meta property="article:published_time" content={post.time2} />
        {setModifiedTime(post.modified)}
        <meta property="og:description" content={post.lead} />
        <meta name="twitter:url" content={"https://gardenia.rs" + location.pathname} />
        <meta name="twitter:title" content={post.title_2 || post.title} />
        <meta name="twitter:description" content={post.lead} />
        <meta name="twitter:image" content={post.pics[0]} />
        {/* End Opengraph */}
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <div id="t1234">
        <img src={`${post.pics[0]}`} alt={post.tags[0]} />
      </div>
      <div className="content">
        <main className="mainContent">
          <article className="mainContent-wrapper">
            <h1 className="mainContent-title">{post.title_2 ? post.title_2 : post.title}</h1>
            {/* <span className="mainContent-cat">{post.category}</span> */}
            <p className="mainContent-lead">
              <strong>{post.lead}</strong>
            </p>
            <div className="time">
              {setSource(post.source)}
              <div>
                , Datum: <TimeFormat timePost={post.modified ? post.modified : post.time2} />
              </div>
            </div>
            <figure className="mainContent-img">
              <img id="levo" src={"/img/article_img/levo.png"} />
              <img src={`${post.pics[0]}`} alt={post.tags[0]} />
              <img id="desno" src={"/img/article_img/desno.png"} />

              <figcaption className="mainContent-img_source">
                <span>Foto: </span>
                <span dangerouslySetInnerHTML={{ __html: post.pics[1] }}></span>
              </figcaption>
            </figure>
            <div className="mainContent-bodyText">
              <BodyText bodyText={post.body} pr={post?.idNum} />
            </div>
            <div className="tags">
              <div className="tags__arr">
                {post.tags.map((item, index) => {
                  return (
                    <Link key={index} to={`/tag/${urlTitle2(item)}`}>
                      {item}
                    </Link>
                  );
                })}
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  );
}
